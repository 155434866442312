import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

jQuery(document).ready(function($) {
	var windowWidth = $(window).width()

/* ====================== MOBILE NAV ======================  */
    $('.menu-toggle').on("click", function() {
        $(this).toggleClass('active');
        $('header nav').toggleClass('open');
        $('header').toggleClass('open');
        $('body').toggleClass('noscroll');
    })

    if(windowWidth < 1280) {
	    $('#menu-main-menu .menu-item-has-children').on("click", function() {
	       $(this).toggleClass('open');
	       $(this).find('.sub-menu').slideToggle();
	    })

	    $('.sub-menu a').on("click", function(event) {
	        event.stopPropagation();
	    });
	}

    $('header .btn').on("click", function() {
        $('header nav').removeClass('open');
        $('header').removeClass('open');
        $('body').removeClass('noscroll');
        $('.menu-toggle').removeClass('active')
    })


/* ====================== Semi Sticky Nav ======================  */
	var prevScrollpos = window.pageYOffset;
	var currentScrollPos = window.pageYOffset;

	if(currentScrollPos > 120) {
		$("header").addClass("headerscrolled");
	}

	document.addEventListener('scroll', function (event) {
		var currentScrollPos = window.pageYOffset;

		if ((prevScrollpos > currentScrollPos)) {
			$('header').addClass('show');
		} else if((prevScrollpos < currentScrollPos)){
			$('header').removeClass('show');
		}
		else {
			$('header').removeClass('show');
		}

		if(currentScrollPos > 120) {
			$("header").addClass("headerscrolled");
		} else {
			$("header").removeClass("headerscrolled show");
		}

		prevScrollpos = currentScrollPos;
	}, true);

/* ====================== Latest Posts Carousel ======================  */
	if(windowWidth < 1024) {
		const mobile_carousel = new Swiper('.mobile-carousel.swiper', {
	       	slidesPerView: 1.2,
	       	speed: 600,
	       	loop: false,
	       	watchSlidesProgress: true,
	       	spaceBetween: 20,
	       	breakpoints: {
				768: {
					slidesPerView: 2.2,
					spaceBetween: 20,
				},
			},
		});
	}

/* ====================== Latest Posts Carousel ======================  */
	const latest_posts_carousel = new Swiper('.latest-posts-carousel .swiper', {
       	slidesPerView: 1.25,
       	speed: 600,
       	loop: false,
       	watchSlidesProgress: true,
       	spaceBetween: 8,
       	navigation: {
			nextEl: ".latest-posts-carousel .swiper-button-next",
			prevEl: ".latest-posts-carousel .swiper-button-prev",
		},
		breakpoints: {
			768: {
				slidesPerView: 2.2,
				spaceBetween: 30,
			},
			1024: {
				slidesPerView: 3.2,
				spaceBetween: 30,
			},

			1280: {
				slidesPerView: 3,
				spaceBetween: 30,
			},
		},
	});

/* ====================== Testimonials Carousel ======================  */
	const testimonials_carousel = new Swiper('.testimonials-carousel .swiper', {
       	slidesPerView: 1,
       	speed: 600,
       	loop: true,
       	watchSlidesProgress: true,
       	spaceBetween: 30,
       	effect: "fade",
       	fadeEffect: { crossFade: true },
       	autoplay: {
        	delay: 6000,
        	disableOnInteraction: false,
      	},
       	navigation: {
			nextEl: ".testimonials-carousel .swiper-next",
			prevEl: ".testimonials-carousel .swiper-prev",
		}
	});

/* ====================== Submit Gravity Form ======================  */
	$(document).on('gform_post_render', function(){
	     $('.gform_fields .btn').on('click', function() {
        	$(this).parents('.gform_wrapper').find('input[type=submit]').click();
        });
	});

/* ====================== GSAP ======================  */
	let animate = gsap.utils.toArray('.animate')
	animate.forEach((item, index) => {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				once: true,
				start:"0 80%",
				toggleActions:"restart none none reset",
				end: "500",
				markers: false,
				toggleClass: 'animateelement'
			}
		});
	});

    // document.querySelectorAll('h1:not(.no-anim):not(.homepage-title), h2:not(.no-anim), h3:not(.no-anim), h4:not(.no-anim)').forEach(function(heading) {
    // 	// stops headings briefly showing
    // 	heading.style.opacity = 1;

	// 	gsap.to(heading.querySelectorAll(".word"), {
	// 		scrollTrigger: {
	// 			trigger: heading,
	// 			start: 'top 85%',
	// 			markers: false,
	// 		},
	// 		y: 0,
	// 		ease: "power4.out",
	// 		opacity: 1,
	// 		duration: .4,
	// 	});
	// 	});


/* ====================== PARALLAX ======================  */
		const parallax = gsap.utils.toArray("svg.parallax");
		parallax.forEach((item, index) => {
			gsap.to(item, {
				bottom: () => -60,
				ease: "none",
				scrollTrigger: {
					trigger: item,
					scrub: 2,
					start: "60% 60%",
					markers: false,
					end: () => '130% 80%',
				}
			});
		});

		const home_parallax = gsap.utils.toArray("svg.homepage-parallax");
		home_parallax.forEach((item, index) => {
			gsap.to(item, {
				bottom: () => -60,
				ease: "none",
				scrollTrigger: {
					trigger: item,
					scrub: 2,
					start: "60% 60%",
					markers: false,
					end: () => '150% 80%',
				}
			});
		});

		const full_size = gsap.utils.toArray(".full-size-image img");
		full_size.forEach((item, index) => {
			gsap.to(item, {
				y: () => -60,
				ease: "none",
				scrollTrigger: {
					trigger: item,
					scrub: 2,
					start: "-20% 60%",
					markers: false,
					end: () => '120% 80%',
				}
			});
		});
/* ====================== Smooth Scroll - Doesn't conflict with ajax smooth scroll ======================  */
	// Add smooth scrolling to all links
	$("a").on('click', function(event) {

	// Make sure this.hash has a value before overriding default behavior
	if (this.hash !== "") {
	  // Prevent default anchor click behavior
	  event.preventDefault();

	  // Store hash
	  var hash = this.hash;

	  // Using jQuery's animate() method to add smooth page scroll
	  // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
	  $('html, body').animate({
	    scrollTop: $(hash).offset().top
	  }, 500, function(){

	    // Add hash (#) to URL when done scrolling (default click behavior)
	    window.location.hash = hash;
	  });
	} // End if
	});
});
